import { MAP_PARAMS, MAP_TILES } from '@repo/common/constants'
import { SentinelDataDto } from '@repo/common/dto'
import { produce } from 'immer'
import * as L from 'leaflet'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { UmbraDto } from '@/common/dto/umbra.dto'
import { getFirstFrameAndTrack } from '@/utils/common'

// State
interface MapState {
  currentMap: L.Map | null
  selectedImages: Record<string, any>
  progressBarImages: Record<string, any>
  currentTileLayer: string
  currentImage: string
  isROIEnabled: boolean
  layers: Record<string, L.Layer>
  totalSteps: number
  currentStep: number
  selectedUmbraImages: UmbraDto[]
  mapCenter: L.LatLngExpression
  selectedLumirImages: any[]
  isProcessROIEnabled: boolean
  roiArea: number
  roiCost: number
}

// Actions
interface MapActions {
  setCurrentMap: (currentMap: L.Map | null) => void
  setCurrentTileLayer: (currentTileLayer: string) => void
  setCurrentImage: (currentImage: string) => void
  setROIEnable: (isROIEnabled: boolean) => void
  addLayer: (key: string, layer: L.Layer) => void
  removeLayer: (key: string) => void
  clearLayers: () => void
  clearSelectedImages: () => void
  getImageCount: (firstFrame: number, track: number) => number
  setTotalSteps: (totalSteps: number) => void
  setCurrentStep: (currentStep: number) => void
  toggleImageOverlay: (data: any) => Promise<any>
  addImageOverlay: (data: any) => Promise<any>
  removeImageOverlay: (data: any) => Promise<any>
  toggleUmbraImage: (imageData: UmbraDto) => void
  updateImageLayerOrder: (
    firstFrame: string,
    track: string,
    currentIndex: number
  ) => void
  setMapCenter: (center: L.LatLngExpression) => void
  toggleLumirImage: (
    imageData: any,
    filter: string,
    method: (title: string) => Promise<any[]>
  ) => void
  clearSelectedLumirImages: () => void
  getPolygonCoords: (polygonString: string) => [number, number][]
  setIsProcessROIEnable: (isProcessROIEnabled: boolean) => void
  setRoiArea: (roiArea: number) => void
  setRoiCost: (roiCost: number) => void
}

type MapStore = MapState & MapActions

// Combined interface
const useMapStore = create<MapStore>()(
  persist(
    (set, get) => ({
      currentMap: null,
      selectedImages: {}, // 선택된 이미지들의 상태
      selectedUmbraImages: [], // 선택된 Umbra 이미지들의 상태
      progressBarImages: {}, // 프로그레스 바에 표시되는 이미지들의 상태
      setCurrentMap: (currentMap) => set(() => ({ currentMap })),
      currentTileLayer: MAP_TILES[0].layerName,
      setCurrentTileLayer: (currentTileLayer) =>
        set(() => ({ currentTileLayer })),
      currentImage: '',
      setCurrentImage: (currentImage) => set(() => ({ currentImage })),
      isROIEnabled: false,
      setROIEnable: (isROIEnabled) =>
        set(() => {
          return {
            isROIEnabled,
          }
        }),
      layers: {},
      addLayer: (key, layer) => {
        const { layers, currentMap } = get()
        if (currentMap) {
          // 새로운 layers 객체 생성
          const newLayers = {
            ...layers,
            [key]: layer,
          }

          // 이미 맵에 추가되어 있지 않은 경우에만 추가
          if (!currentMap.hasLayer(layer)) {
            layer.addTo(currentMap)
          }

          set({ layers: newLayers })
        }
      },

      removeLayer: (key) => {
        const { layers, currentMap } = get()

        if (currentMap && layers[key]) {
          // 맵에서 레이어 제거
          if (currentMap.hasLayer(layers[key])) {
            currentMap.removeLayer(layers[key])
          }

          // 새로운 layers 객체 생성 (제거할 키를 제외)
          const newLayers = Object.entries(layers).reduce(
            (acc, [k, v]) => {
              if (k !== key) {
                acc[k] = v
              }
              return acc
            },
            {} as { [key: string]: L.Layer }
          )

          set({ layers: newLayers })
        }
      },
      clearLayers: () => {
        const { layers, currentMap } = get()
        if (currentMap) {
          Object.values(layers).forEach((layer) =>
            currentMap.removeLayer(layer)
          )
          set({ layers: {} })
        }
      },
      // 기존 MapStore 상태 및 액션...

      clearSelectedImages: () => {
        set((state) => {
          const { currentMap, selectedImages } = state
          if (currentMap) {
            // 맵에서 모든 이미지 오버레이 제거
            Object.keys(selectedImages).forEach((frameNumber) => {
              selectedImages[frameNumber]?.forEach((productName) => {
                currentMap.eachLayer((layer) => {
                  if (
                    layer instanceof L.ImageOverlay &&
                    layer.options.customAttribution ===
                      `${frameNumber}-${productName}`
                  ) {
                    currentMap.removeLayer(layer)
                  }
                })
              })
            })
          }
          return {
            ...state,
            selectedImages: {}, // 모든 선택된 이미지 상태 초기화
          }
        })
      },
      getImageCount: (firstFrame, track) => {
        const images =
          get().selectedImages[getFirstFrameAndTrack(firstFrame, track)]
        return images ? images.length : 0
      },

      // Progress bar state
      totalSteps: 0,
      currentStep: 0,
      setTotalSteps: (totalSteps) => set({ totalSteps }),
      setCurrentStep: (currentStep) => set({ currentStep }),

      toggleImageOverlay: (data: SentinelDataDto) => {
        return new Promise((resolve, reject) => {
          try {
            const state = get() // Assuming get is available in the context
            const firstFrameAndTrack = `${data.firstFrame}-${data.track}`
            const currentMap = state.currentMap
            if (!currentMap) {
              throw new Error('No current map available')
            }

            const selectedImagesForFrameAndTrack =
              state.selectedImages[firstFrameAndTrack] || []
            const isFrameSelected = selectedImagesForFrameAndTrack.some(
              (item) => item.title === data.productName
            )

            set((state) => {
              const updatedImages = { ...state.selectedImages }
              const updatedLayers = { ...state.layers }

              if (isFrameSelected) {
                // Remove image overlay
                currentMap.eachLayer((layer) => {
                  if (
                    layer instanceof L.ImageOverlay &&
                    layer.options.customAttribution ===
                      `${data.firstFrame}-${data.track}-${data.productName}`
                  ) {
                    currentMap.removeLayer(layer)
                    delete updatedLayers[
                      `${data.firstFrame}-${data.track}-${data.productName}`
                    ]
                    resolve({ success: true, action: 'removed', data })
                  }
                })

                updatedImages[firstFrameAndTrack] = updatedImages[
                  firstFrameAndTrack
                ].filter((item) => item.title !== data.productName)
              } else {
                // Add image overlay
                const cachedURL = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}/api/satellites/search/cache/get/image?key=${data.browse}`

                // console.log(data)

                if (!data.imageBounds) {
                  console.error('imageBounds value is missing.')
                  throw new Error('imageBounds value is missing.')
                }
                const imageBounds = data.imageBounds
                const overlay = L.imageOverlay(cachedURL, imageBounds, {
                  opacity: 1,
                  customAttribution: `${data.firstFrame}-${data.track}-${data.productName}`,
                })

                overlay
                  .addTo(currentMap)
                  .on('load', () => {
                    resolve({ success: true, action: 'added', data })
                  })
                  .setZIndex(1000)

                updatedLayers[
                  `${data.firstFrame}-${data.track}-${data.productName}`
                ] = overlay

                updatedImages[firstFrameAndTrack] = [
                  ...(updatedImages[firstFrameAndTrack] || []),
                  {
                    title: data.productName,
                    browse: data.browse,
                    polygon: data.polygon,
                    imageBounds,
                    tags: data.tags,
                    processName: data.processName,
                    firstFrame: data.firstFrame,
                    track: data.track,
                    groupID: data.groupID,
                    downloadUrl: data.downloadUrl,
                    thumbnailUrl: data.thumbnailUrl,
                  },
                ]
              }

              // Sort the images based on data.title
              updatedImages[firstFrameAndTrack].sort((a, b) => {
                const getDateFromTitle = (title: string) => {
                  const parts = title.split('_')
                  return parts[4] // Get the date part (index 4 after splitting)
                }

                const dateA = getDateFromTitle(a.title)
                const dateB = getDateFromTitle(b.title)

                return dateA.localeCompare(dateB)
              })

              // console.log(updatedImages)

              return {
                ...state,
                selectedImages: updatedImages,
                layers: updatedLayers,
              }
            })
          } catch (error) {
            reject(error)
          }
        })
      },

      addImageOverlay: (data) => {
        return new Promise((resolve, reject) => {
          try {
            // console.log(
            //   `Starting to add image overlay for ${data.firstFrame}-${data.track}-${data.productName}`
            // )

            const state = get()
            const firstFrameAndTrack = `${data.firstFrame}-${data.track}`
            const currentMap = state.currentMap
            if (!currentMap) {
              console.error('No current map available')
              throw new Error('No current map available')
            }

            set((state) => {
              const updatedImages = { ...state.progressBarImages }
              const updatedLayers = { ...state.layers }

              const timestamp = new Date().getTime()
              const cachedURL = `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}/api/satellites/search/cache/get/image?key=${data.browse}&t=${timestamp}`

              // console.log(`Image URL: ${cachedURL}`)

              if (!data.imageBounds) {
                console.error('imageBounds value is missing for', data)
                throw new Error('imageBounds value is missing')
              }

              const imageBounds = data.imageBounds
              // console.log(`Image bounds:`, imageBounds)

              const overlay = L.imageOverlay(cachedURL, imageBounds, {
                opacity: 1,
                customAttribution: `${data.firstFrame}-${data.track}-${data.productName}`,
              })

              // console.log(
              //   `Created image overlay for ${data.firstFrame}-${data.track}-${data.productName}`,
              // );

              overlay
                .addTo(currentMap)
                .on('load', () => {
                  // console.log(
                  //   `Image overlay loaded for ${data.firstFrame}-${data.track}-${data.productName}`,
                  // );
                  currentMap.invalidateSize()
                  resolve({ success: true, action: 'added', data })
                })
                .on('error', (error) => {
                  // console.error(
                  //   `Error loading image overlay for ${data.firstFrame}-${data.track}-${data.productName}:`,
                  //   error,
                  // );
                  reject(error)
                })
                .setZIndex(1000)

              const layerKey = `${data.firstFrame}-${data.track}-${data.productName}`
              updatedLayers[layerKey] = overlay
              // console.log(`Added layer to updatedLayers with key: ${layerKey}`);

              updatedImages[firstFrameAndTrack] = [
                ...(updatedImages[firstFrameAndTrack] || []),
                {
                  title: data.productName,
                  browse: data.browse,
                  polygon: data.polygon,
                  imageBounds,
                  tags: data.tags,
                  processName: data.processName,
                  firstFrame: data.firstFrame,
                  track: data.track,
                  groupID: data.groupID,
                },
              ]

              // console.log(
              //   `Updated progressBarImages for ${firstFrameAndTrack}`,
              // );

              return {
                ...state,
                progressBarImages: updatedImages,
                layers: updatedLayers,
              }
            })
          } catch (error) {
            // console.error("Error in addImageOverlay:", error);
            reject(error)
          }
        })
      },
      removeImageOverlay: (data) => {
        return new Promise((resolve, reject) => {
          try {
            const state = get()
            const firstFrameAndTrack = `${data.firstFrame}-${data.track}`
            const currentMap = state.currentMap
            if (!currentMap) {
              throw new Error('No current map available')
            }

            set((state) => {
              const updatedImages = { ...state.selectedImages }
              const updatedLayers = { ...state.layers }

              currentMap.eachLayer((layer) => {
                if (
                  layer instanceof L.ImageOverlay &&
                  layer.options.customAttribution ===
                    `${data.firstFrame}-${data.track}-${data.productName}`
                ) {
                  currentMap.removeLayer(layer)
                  delete updatedLayers[
                    `${data.firstFrame}-${data.track}-${data.productName}`
                  ]
                  resolve({ success: true, action: 'removed', data })
                }
              })

              updatedImages[firstFrameAndTrack] = updatedImages[
                firstFrameAndTrack
              ]?.filter((item) => item.title !== data.productName)

              return {
                ...state,
                selectedImages: updatedImages,
                layers: updatedLayers,
              }
            })
          } catch (error) {
            reject(error)
          }
        })
      },

      updateImageLayerOrder: (firstFrame, track, currentIndex) => {
        const state = get()
        const currentMap = state.currentMap
        if (!currentMap) {
          console.error('No current map available')
          return
        }

        const key = `${firstFrame}-${track}`
        const selectedImagesForFrameAndTrack =
          state.progressBarImages[key] || []

        if (
          currentIndex >= 0 &&
          currentIndex < selectedImagesForFrameAndTrack.length
        ) {
          const currentImage = selectedImagesForFrameAndTrack[currentIndex]
          const layerKey = `${firstFrame}-${track}-${currentImage.title}`

          // 모든 이미지 오버레이의 zIndex를 1000으로 설정
          currentMap.eachLayer((layer) => {
            if (
              layer instanceof L.ImageOverlay &&
              layer.options.customAttribution.startsWith(
                `${firstFrame}-${track}-`
              )
            ) {
              layer.setZIndex(1000)
            }
          })

          // 현재 선택된 이미지의 zIndex를 1001로 설정하여 최상위로 올립니다.
          const selectedLayer = state.layers[layerKey]
          if (selectedLayer) {
            selectedLayer.setZIndex(1001)
          }
        }
      },

      mapCenter: MAP_PARAMS.center, // 기본값 설정
      setMapCenter: (center) => set({ mapCenter: center }),

      selectedLumirImages: [],

      toggleLumirImage: async (imageData, filter, method) => {
        const filteredData = {
          ...imageData,
          title: imageData.title.split(filter)[0], // EX: "_SHIPD"
        }
        // console.log(filteredData);
        const { selectedLumirImages, currentMap } = get()
        // console.log(selectedLumirImages);

        if (!currentMap) {
          return
        }

        const points = await method(filteredData.title)

        // imageData 객체에서 사용할 식별자를 결정합니다.
        const imageId = filteredData.id

        const isSelected = selectedLumirImages.some((img) => img.id === imageId)
        // console.log(isSelected);

        if (isSelected) {
          // 이미지 오버레이 제거 로직
          currentMap.eachLayer((layer) => {
            if (
              layer instanceof L.ImageOverlay &&
              layer.options.id === imageId // 오버레이 식별을 위해 수정된 식별자 확인 로직
            ) {
              // console.log(layer.options);
              currentMap.removeLayer(layer)
            }
          })

          // 점 제거 로직
          currentMap.eachLayer((layer) => {
            if (
              layer instanceof L.CircleMarker &&
              layer.options.id === imageId // 점 식별을 위해 수정된 식별자 확인 로직
            ) {
              currentMap.removeLayer(layer)
            }
          })

          set((state) => ({
            selectedLumirImages: state.selectedLumirImages.filter(
              (img) => img.id !== imageId
            ),
          }))
        } else {
          // 이미지 오버레이 추가 로직
          const imageBounds = L.latLngBounds(
            [imageData.boundingBox.miny, imageData.boundingBox.minx],
            [imageData.boundingBox.maxy, imageData.boundingBox.maxx]
          )

          // console.log(imageBounds);

          const overlayUrl = imageData.imageUrl
            ? `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}${imageData.imageUrl}`
            : `${process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN}/static${imageData.overlayUrl}`

          const overlay = L.imageOverlay(overlayUrl, imageBounds, {
            id: imageId, // 오버레이 식별을 위해 수정된 식별자 사용
          })

          overlay.addTo(currentMap)

          // 점 추가 로직
          points.forEach((point) => {
            const marker = L.circleMarker([point.Lat, point.Lon], {
              id: imageId, // 점 식별을 위해 수정된 식별자 사용
              color: 'red', // 테두리 색상
              fillColor: 'red', // 채우기 색상
              fillOpacity: 0.8, // 채우기 투명도
              radius: 1, // 점의 반지름 (크기)
            })

            marker.addTo(currentMap)
          })

          set((state) => ({
            selectedLumirImages: [
              ...state.selectedLumirImages,
              { ...imageData, id: imageId },
            ], // id 필드를 통합하여 업데이트
          }))

          // 해당 이미지 영역으로 줌인
          currentMap.fitBounds(imageBounds, {
            padding: [200, 200], // 지도 뷰가 이미지 경계에 닿지 않도록 패딩을 추가
          })
        }
      },

      clearSelectedLumirImages: () =>
        set(() => {
          const { currentMap } = get()
          if (currentMap) {
            currentMap.eachLayer((layer) => {
              if (
                layer instanceof L.ImageOverlay ||
                layer instanceof L.CircleMarker
              ) {
                currentMap.removeLayer(layer)
              }
            })
          }
          return {
            selectedLumirImages: [],
          }
        }),

      getPolygonCoords: (image) =>
        image.map((coord) => {
          // console.log(coord)
          const [lon, lat] = coord
            .replace('POLYGON((', '')
            .replace('))', '')
            .split(' ')
            .map(Number)

          return [lat, lon]
        }),

      toggleUmbraImage: (umbra: UmbraDto) => {
        return new Promise((resolve, reject) => {
          try {
            const { currentMap, selectedUmbraImages } = get()
            if (!currentMap) {
              reject(new Error('Map is not initialized'))
              return
            }

            const isSelected = selectedUmbraImages.includes(umbra.id)

            set(
              produce((state) => {
                if (isSelected) {
                  // Remove the image if it's already selected
                  currentMap.eachLayer((layer) => {
                    if (
                      layer instanceof L.ImageOverlay &&
                      layer.options.id === umbra.id
                    ) {
                      currentMap.removeLayer(layer)
                    }
                  })
                  state.selectedUmbraImages = state.selectedUmbraImages.filter(
                    (id) => id !== umbra.id
                  )
                  resolve({ umbra, result: 'deleted' })
                } else {
                  // Add the image if it's not selected
                  const coordinates = get().getPolygonCoords(
                    umbra.stringFootprint
                  )
                  const bounds = L.latLngBounds(
                    coordinates.map((coord) => L.latLng(coord[0], coord[1]))
                  )

                  const prefixUrl =
                    process.env.NODE_ENV === 'development'
                      ? process.env.NEXT_PUBLIC_UMBRA_SERVER_API
                      : process.env.NEXT_PUBLIC_MAIN_SERVER_DOMAIN

                  const imageUrl = `${prefixUrl}/api/search/umbra-static?processedFile=${umbra.processedFile}`

                  const overlay = L.imageOverlay(imageUrl, bounds, {
                    id: umbra.id,
                  })
                    .addTo(currentMap)
                    .on('load', () => {
                      resolve({ umbra, result: 'added' })
                    })
                    .setZIndex(1000)

                  state.selectedUmbraImages.push(umbra.id)

                  // Fit bounds to the image
                  currentMap.fitBounds(bounds, {
                    paddingTopLeft: [500, 100],
                  })
                }
              })
            )
          } catch (error) {
            reject(error)
          }
        })
      },
      isProcessROIEnabled: false,
      setIsProcessROIEnable: (isProcessROIEnabled) =>
        set(() => {
          return {
            isProcessROIEnabled,
          }
        }),

      roiArea: 0,
      setRoiArea: (roiArea) => set(() => ({ roiArea })),
      roiCost: 0,
      setRoiCost: (roiCost) => set(() => ({ roiCost })),
    }),
    {
      name: 'map-store',
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ mapCenter: state.mapCenter }),
    }
  )
)

export default useMapStore
