import { dataCollections } from "@repo/common/constants";
import { Dayjs } from "dayjs";
import { ReadonlyURLSearchParams } from "next/navigation";

export const sidebarAnimation = {
  initial: false,
  animate: (isSidebarClosed: boolean) => ({
    x: !isSidebarClosed ? 0 : -390,
  }),
  transition: { ease: "easeOut", stiffness: 10, duration: 0.2 },
};

export const omitProps = (obj: any, propsToOmit: string[]) => {
  const newObj = { ...obj };
  propsToOmit.forEach((prop) => {
    delete newObj[prop];
  });
  return newObj;
};

export const getFirstFrameAndTrack = (
  firstFrame: string | null,
  track: string | null
) => {
  if (firstFrame !== null && track !== null) {
    return `${firstFrame}-${track}`;
  } else {
    throw new Error("firstFrame and track are not defined");
  }
};

export function getDataFromSearchParams(
  params: URLSearchParams,
  key: string[]
) {
  return key.map((k) => params.get(k));
}

function isReadonlyURLSearchParams(
  param: any
): param is ReadonlyURLSearchParams {
  return param instanceof ReadonlyURLSearchParams;
}

export const isAllOptionsSelected = (
  searchParams: typeof dataCollections | ReadonlyURLSearchParams
) => {
  // 타입에 따라 값 접근 방식 분기
  return Object.keys(dataCollections).every((key) => {
    if (isReadonlyURLSearchParams(searchParams)) {
      // ReadonlyURLSearchParams 인스턴스일 경우 .get() 사용
      const value = searchParams.get(key);
      return value !== null && value !== "";
    } else {
      // DataCollectionsType 객체일 경우 직접 접근
      const value = searchParams[key as keyof typeof dataCollections];
      return value !== undefined && value !== "";
    }
  });
};

export const getTimeAgo = (dateString: string) => {
  const koreaTimeOffset = 9 * 60 * 60 * 1000; // KST, UTC+9
  const nowUTC = new Date().getTime();
  const createdAtUTC = new Date(dateString).getTime();

  const nowKST = new Date(nowUTC);
  const createdAtKST = new Date(createdAtUTC + koreaTimeOffset);
  const diffInMilliseconds =
    koreaTimeOffset + nowKST.getTime() - createdAtKST.getTime();

  const seconds = diffInMilliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  const days = hours / 24;

  if (days >= 1) {
    return `${Math.floor(days)} days ago`;
  } else if (hours >= 1) {
    return `${Math.floor(hours)} hours ago`;
  } else if (minutes >= 1) {
    return `${Math.floor(minutes)} minutes ago`;
  } else {
    return `just now`;
  }
};

export const formatDateToYYYYMMDD: (date: Date) => string = (date) => {
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const setSidebarXPosition: (
  isSidebarClosed: boolean,
  isSecondSidebarClosed: boolean
) => number = (isSidebarClosed, isSecondSidebarClosed) => {
  return !isSecondSidebarClosed ? 385 : !isSidebarClosed ? 0 : -389;
};

export const getYears = (today: Dayjs) => {
  return Array.from(new Array(11), (_, index) => today.year() - 10 + index);
};

export const getMonths = () => {
  return Array.from(new Array(12), (_, index) => index + 1);
};

export const determineRedirectUrl = (sidebarItem, searchParams) => {
  const baseUrl = sidebarItem.href;
  const currentSearchParams = new URLSearchParams(searchParams.toString());

  if (sidebarItem.title.toLowerCase() === "dataset") {
    currentSearchParams.set("menu", "sar");
  }
  return `${baseUrl}?${currentSearchParams.toString()}`;
};
